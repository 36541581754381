import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";
import AxptTable from "../components/table/table";
import { Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface DemoWithOwnDataPageModel {
  title: string;
  _rawDescription: any;
  _rawFieldTable: any;
}

export const query = graphql`
  query DemoWithOwnDataPageQuery {
    site: sanityDemoWithOwnData {
      _rawDescription
      title
      _rawFieldTable
    }
  }
`;

const DemoWithOwnDataPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site: DemoWithOwnDataPageModel = (data || {}).site;
  const tableRows = site._rawFieldTable.rows || [];

  const tableHeader = tableRows && tableRows[0].cells;
  const tableBody = tableRows && tableRows.slice(1);

  const tableRowsMandatory = tableBody.filter(function(row) {
    return row.cells[2].startsWith("Yes");
  });
  const tableRowsNotMandatory = tableBody.filter(function(row) {
    return row.cells[2].startsWith("No");
  });

  return (
    <Layout>
      <SEO title={site.title} />
      <Container role="main">
        <h1>{site.title}</h1>
        {site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Mandatory fields</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <AxptTable tableHeader={tableHeader} tableBody={tableRowsMandatory}></AxptTable>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Not mandatory fields</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <AxptTable tableHeader={tableHeader} tableBody={tableRowsNotMandatory}></AxptTable>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Layout>
  );
};

export default DemoWithOwnDataPage;
