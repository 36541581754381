import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table
} from "@mui/material";
import React from "react";

const AxptTable = props => {
  const tableHeader = props.tableHeader;
  const tableBody = props.tableBody;

  if (tableHeader?.length && tableBody?.length) {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeader.map(headerField => (
                <TableCell sx={{ fontWeight: "bold" }}>{headerField}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBody.map(row => (
              <TableRow key={row._key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {row.cells.map(cell => (
                  <TableCell>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <p>no table :(</p>;
  }
};

export default AxptTable;
